.react-calendar {
  width: 350px;
  max-width: 100%;
  background: theme(colors.background);
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}
.react-calendar__navigation button:disabled {
  background-color: theme(colors.muted.DEFAULT);
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: theme(colors.secondary.DEFAULT);
}
.react-calendar__tile:disabled {
  background-color: theme(colors.muted.DEFAULT);
}
.react-calendar__tile--now {
  background: theme(colors.secondary.DEFAULT);
  color: black !important;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: theme(colors.secondary.DEFAULT);
}

.withAvailabilities > abbr {
  background-color: theme(colors.primary.DEFAULT);
  border-radius: 50% !important;
  display: inline-block !important;
  width: 33.8px;
  padding-bottom: 8px;
  padding-top: 8px;
  color: theme(colors.primary.foreground);
}
.withAvailabilities > abbr:hover {
  opacity: 0.9;
  color: black !important
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: transparent !important;
}
.react-calendar__tile--active {
  background-color: transparent !important;
}
.legend {
  background-color: theme(colors.primary.DEFAULT);
  border-radius: 50% !important;
  width: 10px;
  height: 10px;
}
.rdp-day_today:not(.rdp-day_outside) {
  color: red;
}
